import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Navigation } from '../components/navigation/navigation.component';
import { Blocks } from '../components/blocks/blocks';
import { Footer } from '../components/footer/footer.component';
import '../scss/styles.scss';

export default class extends React.Component<
  GatsbyProps<ContentfulProps<Page>>
> {
  render() {
    const {
      title,
      description,
      environment,
      className,
      blocks,
    } = this.props.data.contentfulPage;
    return (
      <div className={'page ' + className}>
        <Helmet>
          <html lang="en" />
          <title>Norsepower</title>
          <meta
            name="description"
            content={description ? description.description : 'Default'}
          />
          <meta name="robots" content="noindex" />
        </Helmet>

        <Navigation
          config={environment.navigation}
          logo={environment.logo}
          logoAlternative={environment.logoAlternative}
        />
        <main>
          <Blocks blocks={blocks} />
        </main>
        <Footer
          config={environment.footer}
          logo={environment.logoAlternative}
        />
      </div>
    );
  }
}

export const pageQuery = graphql`
  query Page404Query {
    contentfulPage(slug: { eq: "404" }) {
      title
      className
      description {
        description
      }
      environment {
        metaDescription {
          metaDescription
        }
        metaImage {
          file {
            url
          }
        }
        logo {
          file {
            url
          }
        }
        logoAlternative {
          file {
            url
          }
        }
        navigation {
          links {
            title
            path
            children {
              title
              path
            }
          }
        }
        footer {
          left {
            path
            title
          }
          info {
            email
            address
          }
          right {
            path
            href
            title
          }
        }
      }
      blocks {
        ... on Node {
          internal {
            type
          }
        }
        ... on ContentfulMarkdownBlock {
          className
          body {
            body
          }
        }
        ... on ContentfulQuoteBlock {
          cite
          citeTitle
          body {
            body
          }
        }
        ... on ContentfulImageBlock {
          className
          altText
          image {
            file {
              url
            }
          }
        }
        ... on ContentfulVideoEmbeddedBlock {
          video {
            file {
              url
            }
          }
          src
        }
        ... on ContentfulLogosBlock {
          logos {
            file {
              url
            }
            description
          }
        }
        ... on ContentfulContainer {
          title
          layout
          className
          blocks {
            ... on Node {
              internal {
                type
              }
            }
            ... on ContentfulMarkdownBlock {
              className
              body {
                body
              }
            }
            ... on ContentfulImageBlock {
              className
              altText
              image {
                file {
                  url
                }
              }
            }
            ... on ContentfulShowcaseBlock {
              image {
                file {
                  url
                }
              }
              config {
                path
              }
              body {
                body
              }
            }
            ... on ContentfulPersonBlock {
              image {
                file {
                  url
                }
              }
              email
              name
              phone
              title
            }
          }
        }
      }
    }
  }
`;
